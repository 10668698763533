export const config = {
  api: {
    domain:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_DOMAIN
        : process.env.REACT_APP_DEV_API_DOMAIN || "https://localhost:2222",
  },
  mixpanel: {
    token: process.env.REACT_APP_MP_TOKEN || "token",
  },
};
