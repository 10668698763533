import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  CheckCircle,
  Upload,
  DollarSign,
  InfoIcon,
  Menu,
} from "lucide-react";
import { config } from "./config";
import mixpanel from "mixpanel-browser";

export default function LandingPage() {
  const [email, setEmail] = useState("");
  const [ctaLabel, setCtaLabel] = useState("Get Early Access");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });

    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAddToList = async (evt: React.FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault();

      console.log("handleAddToList()");

      setCtaLabel("Loading...");

      const resJSON = await fetch(`${config.api.domain}/list/add`, {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        body: JSON.stringify({ email }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.log("Network error:", error);
          throw new Error("Network Error");
        });
      if (resJSON.status >= 400) {
        throw new Error(resJSON.statusText);
      }
      if (resJSON.error) {
        throw new Error(resJSON.error);
      }

      console.log("Email submitted:", email);

      mixpanel.track("Email Submit - Success", { email });

      setCtaLabel("Success!");

      alert("Success!");
    } catch (error: Error | any) {
      console.log("handleAddToList() error:", error);
      alert(
        "Error: Please try again and/or contact support@smartexpensemanager.com"
      );
      setCtaLabel("Get Early Access");
      mixpanel.track("Email Submit - Failure", { error: error.message });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-gray-100 overflow-hidden">
      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? "bg-gray-900 shadow-lg" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="text-xl sm:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
            SmartExpenseManager
          </div>
          <nav className="hidden md:flex space-x-8">
            {["Features", "Benefits", "Get Started"].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase().replace(" ", "-")}`}
                className="text-sm font-medium hover:text-indigo-400 transition-colors"
              >
                {item}
              </a>
            ))}
          </nav>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden"
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-90 backdrop-blur-sm flex items-center justify-center">
          <nav className="flex flex-col space-y-4">
            {["Features", "Benefits", "Get Started"].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase().replace(" ", "-")}`}
                onClick={() => setIsMenuOpen(false)}
                className="text-xl font-medium hover:text-indigo-400 transition-colors"
              >
                {item}
              </a>
            ))}
          </nav>
        </div>
      )}

      <main className="pt-24 relative">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-500 to-purple-600 opacity-10 filter blur-3xl"></div>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 relative">
          <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-xl rounded-3xl shadow-2xl overflow-hidden border border-gray-700">
            <div className="p-8 sm:p-12">
              <h1 className="text-4xl sm:text-5xl font-extrabold leading-tight mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400">
                Manage your Business Expense Data with Advanced Software
              </h1>
              <p className="text-xl sm:text-2xl text-indigo-200 font-semibold mb-8">
                Set it and forget it: Save time and reduce stress with our smart
                expense management tool
              </p>

              <div className="mb-12" id="features">
                <p className="text-base sm:text-lg text-gray-300 mb-6">
                  Are you a busy small-business owner or independent
                  professional looking for a hassle-free way to manage your
                  finances?
                </p>

                <p className="text-base sm:text-lg text-gray-300 mb-6">
                  In need of a simple, better tool that saves time for you, your
                  team, and/or your accountant?
                </p>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
                  {[
                    {
                      icon: Upload,
                      text: "Rapid onboarding: Get started in minutes and see immediate value.",
                    },
                    {
                      icon: DollarSign,
                      text: "Smart categorization: Automatically sort expenses for your specific industry needs.",
                    },
                    {
                      icon: InfoIcon,
                      text: "Intelligent insights: Discover insights from historical expense data to increase profit margins for your business.",
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="group relative overflow-hidden rounded-xl p-px bg-gradient-to-r from-gray-800 to-gray-700 hover:from-indigo-500 hover:to-purple-500 transition-all duration-300"
                    >
                      <div className="relative p-5 bg-gray-900 rounded-[11px] h-full">
                        <item.icon className="h-8 w-8 mb-4 text-gray-400 group-hover:text-white transition-colors duration-300" />
                        <span className="text-base sm:text-lg font-medium text-gray-300 group-hover:text-white transition-colors duration-300">
                          {item.text}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-12" id="benefits">
                <p className="text-xl sm:text-2xl font-semibold text-indigo-200 mb-6">
                  Our tool instantly provides independent professionals with:
                </p>
                <ul className="space-y-4">
                  {[
                    "Instant upload: Turn your business purchases and subscriptions into organized expense data.",
                    "Expense categorization: Expenses sorted automatically for your specific business.",
                    "AI-powered financial advice: Actionable insights without needing financial expertise.",
                    "Seamless accountant collaboration: Share data effortlessly, potentially reducing accounting fees and increasing tax deductions.",
                    "Intelligent Analysis: Profit margin analysis for your projects all from your expense data.",
                  ].map((item, index) => (
                    <li
                      key={index}
                      className="flex items-start bg-gray-800 bg-opacity-50 p-4 rounded-lg group hover:bg-opacity-70 transition-all duration-300"
                    >
                      <CheckCircle className="h-6 w-6 text-indigo-400 mr-3 flex-shrink-0 group-hover:text-purple-400 transition-colors duration-300" />
                      <span className="text-base sm:text-lg text-gray-300 group-hover:text-white transition-colors duration-300">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <p className="text-2xl sm:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 mb-10">
                Stop stressing over expense management.
              </p>

              <form
                onSubmit={handleAddToList}
                className="space-y-6"
                id="get-started"
              >
                <p className="font-semibold text-indigo-200 text-lg sm:text-xl">
                  Get early lifetime access and receive a 1-month free trial
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <input
                    type="email"
                    required
                    className="flex-grow px-6 py-4 rounded-xl bg-gray-800 bg-opacity-50 border border-gray-700 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 text-base sm:text-lg text-white placeholder-gray-400"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="group relative overflow-hidden rounded-xl bg-gradient-to-r from-indigo-500 to-purple-500 p-px font-semibold text-white inline-flex items-center justify-center"
                  >
                    <span className="absolute inset-0 w-full h-full transition duration-300 transform group-hover:translate-x-full ease"></span>
                    <span className="relative flex items-center justify-center px-8 py-4 bg-gray-900 rounded-[11px] group-hover:bg-opacity-0 transition duration-300">
                      {ctaLabel}
                      <ArrowRight className="ml-2 h-6 w-6 group-hover:translate-x-1 transition-transform duration-300" />
                    </span>
                  </button>
                </div>
              </form>

              <div className="mt-16 text-center">
                <h2 className="text-3xl sm:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-6">
                  Join Thousands of Satisfied Business Owners
                </h2>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "As a freelance graphic designer, I was always behind on
                  bookkeeping. This tool automates everything - it's like having
                  a personal CFO working 24/7!" - Emma L., Freelance Designer
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "I'm a tech solopreneur who hates accounting. This app changed
                  everything. It's hands-off, yet I'm always in control of my
                  finances. Brilliant!" - Ryan M., App Developer
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "Running a small business left me no time for finances. Now, I
                  get daily insights without lifting a finger. It's financial
                  management on autopilot!" - Olivia K., Boutique Owner
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "I'm a self-employed consultant with zero financial expertise.
                  The AI assistant gives me actionable advice I actually
                  understand. Game-changer!" - Sophia R., Business Consultant
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "As a small-business owner, I was drowning in receipts. Now, I
                  snap a photo, and the AI does the rest. My accountant is
                  amazed at how organized I've become!" - Daniel P., Local Cafe
                  Owner
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "I'm a freelancer juggling multiple projects. This tool
                  categorizes my expenses automatically and gives me
                  project-specific insights. It's like magic!" - Mia C.,
                  Freelance Writer
                </p>
                <p className="text-lg sm:text-xl text-indigo-200 mb-10">
                  "As a tech-averse solopreneur, I was skeptical. But the
                  onboarding was a breeze, and now I have crystal-clear
                  financial visibility. It's empowering!" - Leo S., Fitness
                  Trainer
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
